.title {
    display: flex;
    align-items: center;
}
.logo {
    width: 170px;
    margin-right: 30px;
}

.innerTitleBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}